<template>
  <div class="p-4">
    <Breadcrumb>
      <template slot="restPage">
        <!-- <el-breadcrumb-item>
          {{ $t("TeacherCourses.Classes Management") }}
        </el-breadcrumb-item> -->
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle">
      <template slot="button">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('TeacherCourses.Create Class')"
          placement="top"
        >
          <router-link
            :to="{
              name: addCourseLink
            }"
          >
            <el-button size="small" type="success">
              <i class="fa fa-plus"></i>
            </el-button>
          </router-link>
        </el-tooltip>
      </template>
    </Heading>
    <hr />
    <el-input
      v-if="isAdmin"
      ref="filterInput"
      placeholder="Name / Teacher"
      v-model="search.search"
      @keyup.enter.native="searchResult"
    >
      <el-button
        el-button
        slot="append"
        icon="el-icon-search"
        @click="searchResult"
      >
        Search
      </el-button>
    </el-input>
    <template v-if="classes">
      <el-alert
        v-if="resultsTotal > -1"
        class="mt-3"
        type="success"
        :closable="false"
      >
        <span slot="title">
          Results: <b>{{ resultsTotal }}</b>
        </span>
      </el-alert>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <el-table v-if="classes" :data="classes" stripe>
        <el-table-column
          :label="$t('TeacherCourses.Class Name')"
          min-width="150"
        >
          <template slot-scope="scope">
            <router-link
              :to="{
                name: courseDetailLink,
                params: {
                  id: scope.row.id
                }
              }"
            >
              <b>{{ scope.row.name }}</b>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('TeacherCourses.Teacher')"
          min-width="150"
          v-if="isAdmin || isCompany"
        >
          <template slot-scope="scope">
            <b
              class="mr-1"
              v-for="(teacher, index) in scope.row.teacher_users"
              :key="teacher.id"
            >
              <router-link
                v-if="isAdmin"
                :to="{ name: 'AdminViewUser', params: { id: teacher.id } }"
              >
                <template v-if="index < scope.row.teacher_users.length - 1">
                  {{
                    `${user.displayName(
                      teacher.first_name,
                      teacher.last_name
                    )},`
                  }}
                </template>
                <template v-else>
                  {{
                    `${user.displayName(teacher.first_name, teacher.last_name)}`
                  }}
                </template>
              </router-link>
              <template v-else>
                <template v-if="index < scope.row.teacher_users.length - 1">
                  {{
                    `${user.displayName(
                      teacher.first_name,
                      teacher.last_name
                    )},`
                  }}
                </template>
                <template v-else>
                  {{
                    `${user.displayName(teacher.first_name, teacher.last_name)}`
                  }}
                </template>
              </template>
            </b>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TeacherCourses.Class Description')">
          <template slot-scope="scope">
            <span v-if="scope.row.description">
              {{ scope.row.description }}
            </span>
            <span v-else>
              -
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TeacherCourses.Method')" width="80">
          <template slot-scope="scope">
            <div class="text-center text-success" style="cursor: pointer;">
              <el-tooltip
                class="mr-2"
                effect="dark"
                :content="$t('TeacherCourses.In-Person')"
                placement="top"
              >
                <span v-if="scope.row.is_online === 0">
                  <i aria-hidden="true" class="fa fa-building"></i>
                </span>
              </el-tooltip>
              <el-tooltip
                class="mr-2"
                effect="dark"
                :content="$t('TeacherCourses.Online')"
                placement="top"
              >
                <span v-if="scope.row.is_online === 1">
                  <i aria-hidden="true" class="fa fa-globe"></i>
                </span>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TeacherCourses.Class Dates')" width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.started_on">
              <span v-if="scope.row.started_on">{{
                instant.formatGoogleTime(scope.row.started_on)
              }}</span>
              <span v-if="scope.row.ended_on">
                ~
                {{ instant.formatGoogleTime(scope.row.ended_on) }}
              </span>
            </template>
            <template v-else>
              -
            </template>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TeacherCourses.Class Time')" width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.time_from">
              <span>{{ scope.row.time_from }}</span>
              <span v-if="scope.row.time_to">
                ~
                {{ scope.row.time_to }}
              </span>
            </template>
            <template v-else>
              -
            </template>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('TeacherCourses.Number of Students')"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.student_users_count }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('TeacherCourses.Action')"
          fixed="right"
          width="240"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="mr-2"
              effect="dark"
              :content="$t('TeacherCourses.Invite Students')"
              placement="top"
            >
              <el-button
                type="success"
                size="mini"
                @click="setLink(scope.row.invitation_code)"
              >
                <i class="fas fa-user-plus"></i>
              </el-button>
            </el-tooltip>

            <el-tooltip
              class="mr-2"
              effect="dark"
              :content="$t('TeacherCourses.Preview')"
              placement="top"
            >
              <router-link
                :to="{
                  name: courseDetailLink,
                  params: {
                    id: scope.row.id
                  }
                }"
              >
                <el-button type="success" size="mini">
                  <i class="fas fa-eye"></i>
                </el-button>
              </router-link>
            </el-tooltip>
            <el-tooltip
              class="mr-2"
              effect="dark"
              :content="$t('TeacherCourses.Edit Class')"
              placement="top"
            >
              <router-link
                :to="{
                  name: editCourseLink,
                  params: {
                    id: scope.row.id
                  }
                }"
              >
                <el-button type="success" size="mini">
                  <i class="fa fa-edit"></i>
                </el-button>
              </router-link>
            </el-tooltip>
            <el-tooltip
              class="mr-2"
              effect="dark"
              content="Remove Class"
              placement="top"
            >
              <el-button @click="removeClass(scope.row.id)" type="danger" size="mini">
                <i class="fa fa-trash-alt"></i>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <el-dialog
        class="class-link"
        :title="$t('TeacherCourses.Class Invitation Link')"
        :visible.sync="showLink"
        width="50%"
      >
        <p>{{ $t("TeacherCourses.Copy and send this link to students") }}</p>
        <div class="text-center">
          <div class="referral-code">
            <a>
              <span>
                {{ link }}
              </span>
            </a>
            <el-button class="ml-3" type="success" @click="copyLink">
              <i class="fas fa-copy"></i>
              {{ $t("toefl.Copy Link") }}
            </el-button>
          </div>
        </div>
      </el-dialog>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import { user, instant } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import Company from "@/apis/company";
import Class from "@/apis/class.js";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  metaInfo() {
    return {
      title: "SAT Practices - " + this.CompanyName
    };
  },

  components: { Breadcrumb },

  mixins: [Common],

  props: [],
  data() {
    return {
      search: {
        search: null
      },
      practices: null,
      resultsTotal: 0,
      pageSize: 10,
      subjects: [],
      classes: [],
      showLink: false,
      link: ""
      // composePractices: null
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    addCourseLink() {
      if (this.isAdmin) {
        return "AdminSatAddCourse";
      } else if (this.isTeacher) {
        return "TeacherSatAddCourse";
      } else if (this.isCompany) {
        return "CompanySatAddCourse";
      } else {
        return "TeacherAddCourse";
      }
    },
    editCourseLink() {
      if (this.isAdmin) {
        return "AdminEditCourse";
      } else if (this.isTeacher) {
        return "TeacherEditCourse";
      } else if (this.isCompany) {
        return "CompanyEditCourse";
      } else {
        return "AdminEditCourse";
      }
    },
    courseDetailLink() {
      if (this.isAdmin) {
        return "AdminCourseDetail";
      } else if (this.isTeacher) {
        return "TeacherCourseDetail";
      } else if (this.isCompany) {
        return "CompanyCourseDetail";
      } else {
        return "AdminCourseDetail";
      }
    },
    myTitle() {
      return this.$t("TeacherCourses.View Class");
    },
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    theme() {
      return Theme.name;
    },
    isAdmin() {
      return this.$route.name === "AdminSatCourses";
    },
    isTeacher() {
      return this.$route.name === "TeacherSatCourses";
    },
    isCompany() {
      return this.$route.name === "CompanySatCourses";
    },
    companyId() {
      return this.getProfile.company_id;
    }
  },
  watch: {
    companyId() {
      this.getClasses();
    }
  },

  async mounted() {
    if (this.$route.query.search) {
      this.search.search = this.$route.query.search;
    }
    this.getClasses();
  },

  methods: {
    removeClass(id) {
      this.$confirm(
        this.$t("newCourses.This will delete it forever, Continue? "),
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        let role = "teacher";
        if (this.isAdmin) {
          role = "admin";
        }
        if (this.isCompany) {
          role = "company";
        }
        if (this.isTeacher) {
          role = "teacher";
        }
        await Class.removeClass(role, id);
        this.getClasses();
      });
    },
    setLink(code) {
      this.link = `${baseDomainPath}join?code=${code}`;
      this.showLink = true;
    },
    async getClasses() {
      // if (this.isAdmin) {
      //   const res = await Class.getClasses({
      //     per_page: this.pageSize,
      //     keyword: this.search.search,
      //     page: this.page
      //   });
      //   this.classes = res.session_classes.data;
      //   this.resultsTotal = res.session_classes.total;
      // }
      // if (this.isTeacher) {
      //   const res = await Class.getClassesForTeacher({
      //     per_page: this.pageSize,
      //     page: this.page
      //   });
      //   this.classes = res.session_classes.data;
      //   this.resultsTotal = res.session_classes.total;
      // }
      // if (this.isCompany) {
      //   if (this.companyId) {
      //     const res = await Company.getCompanyClasses(this.companyId, {
      //       per_page: this.pageSize,
      //       page: this.page
      //     });
      //     this.classes = res.session_classes.data;
      //     this.resultsTotal = res.session_classes.total;
      //   }
      // }
    },
    async searchResult() {
      this.$router.replace({
        params: {
          ...this.$route.params
        },
        query: {
          ...this.$route.query,
          ...this.search,
          page: 1
        }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.showLink = false;
      this.$message({
        message: "Copied",
        type: "success"
      });
    }
  }
};
</script>

<style scoped>
.referral-code span {
  display: inline-block;
  height: 41px;
  line-height: 41px;
  padding: 0 1rem;
  font-size: 1rem;
  background: rgb(241, 243, 244);
  border-radius: 4px;
}
::v-deep .class-link .el-dialog__body {
  padding-top: 0.2rem;
}
</style>
